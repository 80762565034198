import { type Shift } from "@src/appV2/Shifts/Shift/types";
import { differenceInMinutes, parseISO } from "date-fns";

import { MIN_SHIFT_LENGTH_FOR_MANDATORY_BREAK_IN_MINUTES } from "../Shift/constants";

/**
 * Returns whether a shift requires a mandatory break based on its duration and facility settings.
 */
export function doesShiftRequireBreak(shift: Pick<Shift, "start" | "end" | "facility">): boolean {
  const requiresLunchBreak = shift.facility.requiresLunchBreak ?? false;
  const shiftDurationInMinutes = differenceInMinutes(parseISO(shift.end), parseISO(shift.start));

  return (
    requiresLunchBreak && shiftDurationInMinutes > MIN_SHIFT_LENGTH_FOR_MANDATORY_BREAK_IN_MINUTES
  );
}
